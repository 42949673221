import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { BottomDivider } from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Lottie from '@rsa-digital/evo-shared-components/components/Lottie';
import Spinner from '@rsa-digital/evo-shared-components/components/Spinner';
import {
  ReasonList,
  TextWrapper,
} from '@rsa-digital/evo-shared-components/components/WhyChooseUs/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/MoreThan/colors';
import styled from 'styled-components';

export const LoadingQuoteText = styled.h1`
  ${fonts.headingSmall}
  margin-top: ${spacing(2)};
`;

export const SpinnerAndLoadingTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}

  ${mediaQuery.tabletLandscape`
    flex-direction: column;
  `}
`;

export const SpinnerWithMargin = styled(Spinner)`
  margin-right: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0;
  `}
`;

export const Subheading = styled.h2`
  ${fonts.headingLarge}
  margin: ${spacing(1)} 0 ${spacing(3)};
`;

export const DividerWithTopMargin = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(8)};
`;

export const StyledGridItem = styled(GridItem)`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${spacing(4)};
`;

export const GridItemWithTopMargin = styled(GridItem)`
  ${mediaQuery.tabletPortrait`
    && {
      margin-top: ${spacing(3)};
    }
  `};
  display: flex;
  flex-direction: column;
  align-items: center;
  ::after {
    content: '';
    height: 1px;
    width: 50%;
    border-bottom: thin solid ${colors.cardBorder};
    ${mediaQuery.tabletLandscape`
      border-bottom-style: none;
  `};
  }
`;

export const WhyChooseUsGridItem = styled(GridItem)`
  ${TextWrapper} {
    align-items: center;
    text-align: center;
  }
  ${mediaQuery.tabletPortrait`
      ${TextWrapper} {
        align-items: flex-start;
        text-align: left;
      }
  `};
  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
    ${ReasonList} {
      border-left: thin solid ${colors.cardBorder};
      padding-left: ${spacing(6)};
      margin-left: ${spacing(6)};
    }
  `};
`;

export const LottieHiddenOnMobile = styled(Lottie)`
  display: none;
  ${mediaQuery.tabletPortrait`
      display: block;
  `};
`;

export const FlexGrid = styled(Grid)`
  display: flex;
`;

export const BottomDividerWithTopMargin = styled(BottomDivider)`
  margin-top: ${spacing(4)};
`;

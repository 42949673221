import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { LottieAnimation } from '@rsa-digital/evo-shared-components/components/Lottie';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { getCoverLevelFromQuote } from 'apiHelpers/quote/bundleCoverMapping';
import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import useRetrieveQuote from 'apiHelpers/quote/useRetrieveQuote';
import quoteClient, { DeeplinkRequest } from 'apiHelpers/quoteClient';
import { PetCoverLevelReferenceData } from 'apiHelpers/referenceData/petCoverLevel';
import { shouldAggsQuoteShowAdditionalQuestions } from 'businessLogic/aggregators';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import Layout from 'components/Layout';
import WhyChooseUsSection from 'components/WhyChooseUsSection';
import { CoverLevel } from 'helpers/businessConstants';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackDeeplinkReferral } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import { useInitialiseQuoteWithCoverLevel } from 'state/quote/loadQuoteHelper';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsAsset } from 'types/contentStack';
import {
  BottomDividerWithTopMargin,
  FlexGrid,
  GridItemWithTopMargin,
  LoadingQuoteText,
  LottieHiddenOnMobile,
  SpinnerAndLoadingTextContainer,
  SpinnerWithMargin,
  StyledGridItem,
  Subheading,
  WhyChooseUsGridItem,
} from './styles';

type LoadingQuoteLocation = Location & {
  state?: {
    shouldNotInvalidateAssumptions?: boolean;
  };
};

type LoadingQuoteProps = {
  data: {
    csPetLoadingPage: {
      loading_quote_text: string;
      meta_title: string;
      subheading: string;
      navigation_warning_text: string;
      animation: CsAsset;
    };
  };
  location: LoadingQuoteLocation;
};

export const query = graphql`
  query {
    csPetLoadingPage {
      meta_title
      loading_quote_text
      subheading
      navigation_warning_text
      animation {
        ...CsAsset
      }
    }
  }
`;

const getDeepLinkParams = (location: Location): DeeplinkRequest | null => {
  const quoteNumber = getQueryParam(location, 'qn');
  const postcode = getQueryParam(location, 'pc');
  const checksum = getQueryParam(location, 'checksum');
  const msmUrn = getQueryParam(location, 'URN');
  const msmSsid = getQueryParam(location, 'ssid');
  const gocoUuid = getQueryParam(location, 'convert_uuid');

  if (!quoteNumber || !postcode || !checksum) {
    return null;
  }

  return {
    qn: quoteNumber,
    pc: postcode,
    checksum,
    msmUrn: msmUrn ?? undefined,
    msmSsid: msmSsid ?? undefined,
    gocoUuid: gocoUuid ?? undefined,
  };
};

const getCoverLevelFromQueryParams = (
  location: Location,
  coverLevels: PetCoverLevelReferenceData
): CoverLevel | undefined => {
  // The tier gotten from the aggregator doesn't always match exactly with the reference data, but they only differ
  // by non alphanumeric characters, so we remove any non alphanumeric characters before comparing.
  const invalidRegex = new RegExp('[^A-Za-z0-9]', 'g');
  const aggregatorCoverLevel = getQueryParam(location, 'tier')?.replace(invalidRegex, '');

  if (!aggregatorCoverLevel) {
    return undefined;
  }

  // The tier param is sometimes sent with a &, so the full value isn't picked up and needs to be checked manually
  // On MT, the Premier coverLevels have an extra MT in their name, so these cases must be handled separately
  switch (aggregatorCoverLevel) {
    case 'Premier1000':
      return CoverLevel.PREMIER_1000;
    case 'Premier2000':
      return CoverLevel.PREMIER_2000;
    case 'Premier4000':
      return CoverLevel.PREMIER_4000;
    case 'Premier6000':
      return CoverLevel.PREMIER_6000;
    default:
      return coverLevels.petCoverLevel.find(
        (level) => level.name.replace(invalidRegex, '') === aggregatorCoverLevel
      )?.value as CoverLevel | undefined;
  }
};

const LoadingQuote: React.FC<LoadingQuoteProps> = ({
  data: {
    csPetLoadingPage: {
      navigation_warning_text,
      subheading,
      animation,
      meta_title,
      loading_quote_text,
    },
  },
  location,
}) => {
  const initialiseQuoteWithCoverLevel = useInitialiseQuoteWithCoverLevel();
  const defaultErrorHandling = useDefaultErrorHandling();
  const [requestStarted, setRequestStarted] = useReducer(() => true, false);

  const { createQuote } = useGenerateQuote(
    location.state?.shouldNotInvalidateAssumptions
  );

  const disableDateChecks = useDisableDateChecks();
  const petCoverLevels = useReferenceData('petCoverLevel');

  const { retrieveQuoteByReferenceAndNavigate } = useRetrieveQuote();

  const fetchQuoteAndMoveNext = useCallback(async (): Promise<void> => {
    try {
      const deepLinkParams = getDeepLinkParams(location);
      if (deepLinkParams) {
        if (!petCoverLevels) {
          return;
        }

        const aggregatorDeeplinkCoverLevel = getCoverLevelFromQueryParams(
          location,
          petCoverLevels
        );

        const isDeeplinkFromAggregator = !!aggregatorDeeplinkCoverLevel;

        setRequestStarted();

        if (!isDeeplinkFromAggregator) {
          await retrieveQuoteByReferenceAndNavigate(deepLinkParams.pc, deepLinkParams.qn);
          return;
        }

        const quote = await quoteClient.deeplink({
          ...deepLinkParams,
          disableDateChecks,
        });

        const coverLevel = aggregatorDeeplinkCoverLevel || getCoverLevelFromQuote(quote);

        /* Because an extra requote is done (Re: The "TODO: EP-666 & EP-667" comment), the default quoteOptions
         * are used, which in the MT case, results in an invalid quote (Accident Only + invalid default voluntary excess)
         * so we need to use the initial quote's coverLevel (instead of the default Accident Only).
         * We set the userSelectedCover to true to force using these values when requoting (useInitialiseQuote ->
         * mapFormToRequote -> getQuoteOptionsFromQuote -> hasUserSelectedCover -> userSelectedCover)
         */
        quote.petInfos[0].coverLevelRequired = coverLevel || CoverLevel.BASIC_1500;
        quote.petInfos[0].userSelectedCover = true;

        await initialiseQuoteWithCoverLevel(quote, coverLevel);

        if (shouldAggsQuoteShowAdditionalQuestions(quote)) {
          navigate(quoteAndBuyRoutes.additionalQuestions, { replace: true });
        } else {
          navigate(quoteAndBuyRoutes.quoteSummary, { replace: true });
        }
      } else {
        setRequestStarted();
        await createQuote();
        navigate(quoteAndBuyRoutes.quoteSummary, { replace: true });
      }
    } catch (err) {
      const error: Error = err as Error;
      defaultErrorHandling(error);
    }
  }, [
    createQuote,
    defaultErrorHandling,
    disableDateChecks,
    initialiseQuoteWithCoverLevel,
    location,
    petCoverLevels,
    retrieveQuoteByReferenceAndNavigate,
  ]);

  useEffect(() => {
    if (getDeepLinkParams(location)) {
      trackDeeplinkReferral(document.referrer);
    }
  }, [location]);

  useEffect(() => {
    if (!requestStarted) {
      fetchQuoteAndMoveNext();
    }
  }, [fetchQuoteAndMoveNext, requestStarted]);

  const lottieAnimation: LottieAnimation = {
    url: animation.localAsset.publicURL,
    altText: animation.description || undefined,
  };

  const quote = useCurrentQuote();

  usePageTracking(meta_title, !!quote.petInfos && quote.petInfos[0].userSelectedCover);

  useEffect(() => {
    const unloadCallback = (event: {
      preventDefault: () => void;
      returnValue: string;
    }): string => {
      event.preventDefault();
      const ev = event;
      ev.returnValue =
        'Refreshing the page may cause you to lose your data. Are you sure you want to refresh?';
      return event.returnValue;
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <Layout pageTitle={PageTitle.QuoteGenerating} metaTitle={meta_title}>
      <FlexGrid>
        <GridItemWithTopMargin desktop={5} tabletLandscape={5} tabletPortrait={6}>
          <StyledGridItem desktop={5} tabletLandscape={5} tabletPortrait={6}>
            <SpinnerAndLoadingTextContainer>
              <SpinnerWithMargin />
              <LoadingQuoteText>{loading_quote_text}</LoadingQuoteText>
            </SpinnerAndLoadingTextContainer>
            <GridItem
              desktop={4}
              tabletLandscape={4}
              tabletPortrait={3}
              mobile={2}
              aria-hidden>
              <LottieHiddenOnMobile animation={lottieAnimation} />
            </GridItem>
            <Subheading>{subheading}</Subheading>
            <RichText html={navigation_warning_text} />
          </StyledGridItem>
        </GridItemWithTopMargin>
        <WhyChooseUsGridItem desktop={5} tabletLandscape={5} tabletPortrait={6}>
          <WhyChooseUsSection pageTitle={PageTitle.QuoteGenerating} />
        </WhyChooseUsGridItem>
      </FlexGrid>
      <BottomDividerWithTopMargin />
    </Layout>
  );
};

export default LoadingQuote;
